<template>
  <svg viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <path
        d="M25.52 4.01h-4.89a.63.63 0 0 0-.43.18.59.59 0 0 0-.18.41v4.76c0 .16.07.3.18.42.11.11.27.17.43.17h4.9a.63.63 0 0 0 .43-.17.59.59 0 0 0 .17-.42V4.6c0-.16-.06-.3-.18-.42a.63.63 0 0 0-.43-.17Zm-.57 4.8H21.2l.01-3.66h3.74v3.67Zm5-7.64c-.01-.3-.14-.6-.37-.83A1.25 1.25 0 0 0 28.7 0L1.22.08a1.27 1.27 0 0 0-.86.34 1.18 1.18 0 0 0-.36.84l.06 19.57c0 .3.13.6.36.83.23.22.54.34.87.34l27.5-.08c.32 0 .63-.12.85-.35.23-.22.36-.52.36-.83l-.06-19.57ZM1.2 20.82H1.2L1.14 1.26v-.01l27.66-.08.07 19.56-27.66.08Zm17.35-6.13H5.2v-1.18H18.57v1.18ZM7.95 6.4H5.2V5.2h2.75v1.18Zm15.13 10.68H5.2v-1.19h17.87v1.19Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" d="M0 0h30v22H0z" /></clipPath>
    </defs>
  </svg>
</template>
